import { useRef } from "react";
import useDropdown from "../../../hooks/useDropdown";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";
import DataTableView from "./DataTableView";
import { useState } from "react";
import useCustomEffect from "../../../hooks/useCustomEffect";
import { useLoadContext } from "../../../context/LoadContext";

export default function DataTable({ controller }) {
  return <DataTableView controller={controller} />;
}

/**
 * @param {DataTableConfig} config
 */
export function useDataTable(config) {
  const callEvaApi = useEvaApi();
  const loadContext = useLoadContext();
  const updatedSearches = useRef([]);
  const preventFilterFetch = useRef(false);
  const settingsDropdown = useDropdown();
  const columns = createColumns();
  const [refresh, setRefresh] = useState(false); //eslint-disable-line no-unused-vars
  const [filters, setFilters] = useState({
    where: [],
    limit: getInitialLimit(),
    page: 1,
    order_by: getInitialOrderBy(),
    order_direction: getInitialOrderDirection(),
  });
  const [data, setData] = useState({
    records: [],
    pagination: null,
  });

  /**
   * UseEffect for automated fetches on filters change
   */
  useCustomEffect(() => {
    if (preventFilterFetch.current) {
      preventFilterFetch.current = false;
      return;
    }
    fetch();
  }, [filters]);

  /**
   * Automatically save the filter settings when they change
   */
  useCustomEffect(() => {
    localStorage.setItem(
      config.getSettingsKey(),
      JSON.stringify({
        ...getStorageSettings(),
        limit: filters.limit,
        orderBy: filters.order_by,
        orderDirection: filters.order_direction,
      })
    );
  }, [filters]);

  /**
   * This method will get the initial limit
   * @returns {int}
   */
  function getInitialLimit() {
    const settings = getStorageSettings();
    if (settings?.limit && config.limits.includes(settings.limit)) {
      return settings.limit;
    } else {
      return config.limits.find((l) => l === config.limit) || config.limits[0];
    }
  }

  /**
   * Returns the initial order by
   * @returns {string}
   */
  function getInitialOrderBy() {
    const settings = getStorageSettings();
    if (
      settings?.orderBy &&
      config.columns.find((c) => c.key === settings.orderBy)?.orderable
    ) {
      return settings.orderBy;
    } else {
      return config.columns.find((c) => c.key === config.orderBy)?.orderable
        ? config.orderBy
        : null;
    }
  }

  /**
   * Returns the initial order direction
   * @returns {string} asc or desc
   */
  function getInitialOrderDirection() {
    const settings = getStorageSettings();
    return settings?.orderDirection ?? config.orderDirection;
  }

  /**
   * Returns the columns in a usable format
   * @returns {object[]}
   */
  function createColumns() {
    const settings = getStorageSettings();
    return config.columns
      .map((column, index) => {
        const settingsColumn = settings?.columns?.find(
          (settingcol) => settingcol.key === column.key
        );
        return {
          config: column,
          active:
            !column.hidden &&
            (column.lockActive || (settingsColumn?.active ?? column.active)),
          order: settingsColumn?.order ?? index,
        };
      })
      .sort((a, b) => a.order - b.order);
  }

  /**
   * This method will fetch the data from the API
   */
  async function fetch() {
    settingsDropdown.setOpen(false);

    await callEvaApi(
      new EvaApiCall(config.fetchRequest)
        .setLoadingGroup(config.getFetchKey())
        .setHeaders(config.fetchHeaders)
        .setParams(getFetchParams())
        .setOnSuccess((response) => {
          setData(response.data.data);
        })
    );
  }

  /**
   * This method will return the fetch params
   * @returns {object}
   */
  function getFetchParams() {
    const fetchParams = { ...filters };
    const configParams = { ...config.fetchParams };

    if (configParams.where) {
      fetchParams.where = [...fetchParams.where, ...configParams.where];
      delete configParams.where;
    }

    return { ...fetchParams, ...configParams };
  }

  /**
   * This method will get the settings from the local storage
   * @returns {object}
   */
  function getStorageSettings() {
    return JSON.parse(localStorage.getItem(config.getSettingsKey()));
  }

  /**
   * This method will save the column settings to the local storage
   * @param {object[]} newColumns
   */
  function saveColumnSettings(newColumns) {
    localStorage.setItem(
      config.getSettingsKey(),
      JSON.stringify({
        ...getStorageSettings(),
        columns: newColumns.map((column, index) => ({
          key: column.config.key,
          active: column.active,
          order: index,
        })),
      })
    );

    setRefresh((current) => !current);
  }

  /**
   * This method will handle the limit change
   * @param {any} value
   */
  function onLimitChange(value) {
    if (!loadContext.isLoading(config.getFetchKey())) {
      setFilters((current) => ({
        ...current,
        page: 1,
        limit: value,
      }));
    }
  }

  /**
   * Handles the order by button press of the given column
   * @param {object} column
   */
  function onOrderByPress(column) {
    if (!loadContext.isLoading(config.getFetchKey())) {
      setFilters((current) => ({
        ...current,
        order_by: column.config.key,
        order_direction:
          column.config.key !== current.order_by
            ? current.order_direction
            : current.order_direction === "asc"
            ? "desc"
            : "asc",
      }));
    }
  }

  /**
   * Handles the search button press of the given column
   * @param {object} column
   */
  function onSearchPress(column) {
    if (!loadContext.isLoading(config.getFetchKey())) {
      preventFilterFetch.current = true;
      setFilters((current) => ({
        ...current,
        page: 1,
        where: [
          ...current.where,
          { column: column.config.key, values: [{ value: "" }], in_th: true },
        ],
      }));
    }
  }

  /**
   * Handles the search cancel button press of the given column
   * @param {object} column
   */
  function onSearchCancelPress(column) {
    if (!loadContext.isLoading(config.getFetchKey())) {
      const newWhere = [...filters.where];
      const index = findThWhereIndex(column.config.key, newWhere);

      preventFilterFetch.current =
        updatedSearches.current.length === 0 &&
        newWhere[index].values[0].value.length <= 0;

      newWhere.splice(index, 1);

      setFilters((current) => ({
        ...current,
        page: 1,
        where: newWhere,
      }));
    }
  }

  /**
   * Handles the search input onchange of the given column
   * @param {event} e
   * @param {object} column
   */
  function onSearchChange(e, column) {
    if (!loadContext.isLoading(config.getFetchKey())) {
      const newWhere = [...filters.where];
      const index = findThWhereIndex(column.config.key, newWhere);
      newWhere[index].values[0].value = e.target.value;

      if (!updatedSearches.current.includes(column.config.key)) {
        updatedSearches.current.push(column.config.key);
      }

      preventFilterFetch.current = true;
      setFilters((current) => ({
        ...current,
        page: 1,
        where: newWhere,
      }));
    }
  }

  /**
   * Handles the search input on enter press of the given column
   * @param {object} column
   */
  function onSearchConfirm(column) {
    if (
      !loadContext.isLoading(config.getFetchKey()) &&
      updatedSearches.current.length > 0
    ) {
      updatedSearches.current = [];

      setFilters((current) => ({
        ...current,
        page: 1,
      }));
    }
  }

  /**
   * Handles the page change
   * @param {int} page
   */
  function onPageChange(page) {
    if (!loadContext.isLoading(config.getFetchKey())) {
      setFilters((current) => ({
        ...current,
        page: page,
      }));
    }
  }

  /**
   * Handles the reset of the settings
   */
  function onSettingsReset() {
    localStorage.removeItem(config.getSettingsKey());

    setFilters((current) => {
      const newFilters = {
        ...current,
        limit: getInitialLimit(),
        order_by: getInitialOrderBy(),
        order_direction: getInitialOrderDirection(),
      };

      preventFilterFetch.current =
        newFilters.limit === current.limit &&
        newFilters.order_by === current.order_by &&
        newFilters.order_direction === current.order_direction;

      return newFilters;
    });
  }

  /**
   * Handles the active change of the given column
   * @param {object} column
   */
  function onColumnActiveChange(column) {
    const value = !column.active;
    if (columns.filter((c) => c.active).length === 1 && value === false) {
      return;
    }

    const newColumns = [...columns];
    const index = newColumns.findIndex(
      (c) => c.config.key === column.config.key
    );
    newColumns[index].active = value;
    saveColumnSettings(newColumns);

    //Remove the where filter if the column is not active
    const whereIndex = value
      ? -1
      : findThWhereIndex(column.config.key, filters.where);
    if (whereIndex !== -1 && !value) {
      setFilters((current) => ({
        ...current,
        page: 1,
        where: current.where.filter((w, i) => i !== whereIndex),
      }));
    }
  }

  /**
   * Handles the from-to datetime change of the given column and operator
   * @param {event} e
   * @param {object} column
   * @param {string} operator
   */
  function onFromToDateTimeChange(e, column, operator) {
    if (loadContext.isLoading(config.getFetchKey())) {
      return;
    }

    //find the where index
    let newWhere = [...filters.where];
    const index = findFromToWhereIndex(column.config.key, operator, newWhere);

    //Remove on empty change
    if (e.target.value.length <= 0) {
      if (index !== -1) {
        newWhere.splice(index, 1);
      }
    }
    //Create or update on normal change
    else {
      if (index !== -1) {
        newWhere[index].values[0].value = e.target.value;
      } else {
        newWhere.push({
          column: column.config.key,
          values: [
            {
              operator: operator,
              value: e.target.value,
            },
          ],
        });
      }
    }

    //Update the filters
    setFilters((current) => ({
      ...current,
      page: 1,
      where: newWhere,
    }));
  }

  /**
   * This method will handle the column order change
   * @param {string} draggedId
   * @param {string} targetId
   */
  function onColumnDragEnter(draggedId, targetId) {
    if (draggedId && targetId) {
      const draggedKey = draggedId.replace("DRAGGABLE_COLUMN_", "");
      const targetKey = targetId.replace("DRAGGABLE_COLUMN_", "");

      const newColumns = [...columns];

      const draggedIndex = newColumns.findIndex(
        (c) => c.config.key === draggedKey
      );
      const targetIndex = newColumns.findIndex(
        (c) => c.config.key === targetKey
      );

      const draggedColumn = newColumns[draggedIndex];
      newColumns.splice(draggedIndex, 1);
      newColumns.splice(targetIndex, 0, draggedColumn);
      saveColumnSettings(newColumns);

      return true;
    }
  }

  /**
   * This method will find the where index of the given column
   * @param {string} column
   * @param {array} where
   * @returns {int}
   */
  function findThWhereIndex(column, where) {
    return where.findIndex((w) => w.column === column && w.in_th);
  }

  /**
   * This method will find the where index of the given column
   * @param {string} column
   * @param {string} operator
   * @param {array} where
   * @returns {int}
   */
  function findFromToWhereIndex(column, operator, where) {
    return where.findIndex(
      (w) =>
        w.column === column &&
        w.in_from_to &&
        w.values.length > 0 &&
        w.values[0].operator === operator
    );
  }

  return {
    config,
    data,
    filters,
    columns,
    settingsDropdown,
    fetch,
    onLimitChange,
    onOrderByPress,
    onSearchPress,
    onSearchCancelPress,
    onSearchChange,
    onSearchConfirm,
    onPageChange,
    onSettingsReset,
    onColumnActiveChange,
    onColumnDragEnter,
    onFromToDateTimeChange,
  };
}
