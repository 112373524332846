import { useState } from "react";
import { useParameterContext } from "../../../../../context/ParameterContext";
import useEvaApi, { EvaApiCall } from "../../../../../hooks/useEvaApi";
import ManualSignOutPageView from "./ManualSignoutPageView";
import useCustomEffect from "../../../../../hooks/useCustomEffect";

function ManualSignOutPage() {
  const callEvaApi = useEvaApi();
  const { getParameter } = useParameterContext();
  const [visitors, setVisitors] = useState([]);
  const [search, setSearch] = useState("");
  const filteredVisitors = getFilteredVisitors();
  const selectedVisitor =
    filteredVisitors.length === 1 && search.length >= 1
      ? filteredVisitors[0]
      : null;

  /**
   * UseEffect for automatically fetching the visitors
   */
  useCustomEffect(() => {
    fetchVisitors();
  });

  /**
   * This method will fetch the visitors from the API
   */
  async function fetchVisitors() {
    await callEvaApi(
      new EvaApiCall("visitors")
        .setLoadingGroup("sign_out_fetch")
        .setParams({
          with_visitor_fields_data: true,
          with_visitor_questions_data: true,
          with_visitor_houserules_data: true,
          where: [
            {
              column: "signed_in_at",
              values: [{ value: "__NULL__", operator: "!=" }],
            },
          ],
        })
        .setOnSuccess((response) => {
          setVisitors(response.data.data.records);
        })
    );
  }

  /**
   * This method will return the filtered visitors based on the search value
   * @returns {object[]}
   */
  function getFilteredVisitors() {
    return visitors.filter((visitor) => {
      return getVisitorSearchValue(visitor)
        .toLowerCase()
        .includes(search.toLowerCase());
    });
  }

  /**
   * This method will return the search value for the visitor
   * @param {object} visitor
   * @returns {string}
   */
  function getVisitorSearchValue(visitor) {
    const searchFields = getParameter("visitor.signout.search.fields")?.fields;
    return searchFields
      .map(
        (searchField) =>
          visitor.fields.find((visField) => visField.field === searchField)
            ?.value
      )
      .filter((value) => value !== undefined && value !== null)
      .join(" ");
  }

  /**
   * This method will handle the search change event
   * @param {event} e
   */
  function onSearchChange(e) {
    setSearch(e.target.value);
  }

  return (
    <ManualSignOutPageView
      search={search}
      selectedVisitor={selectedVisitor}
      getVisitorSearchValue={getVisitorSearchValue}
      onSearchChange={onSearchChange}
    />
  );
}

export default ManualSignOutPage;
