import { useFrontofficeContext } from "../../context/FrontofficeContext";
import useScannerListener from "../../hooks/useScannerListener";
import FrontofficePageView from "./FrontofficePageView";

function FrontofficePage() {
  const { updatePage, pageName } = useFrontofficeContext();
  useScannerListener(onQRScan);

  /**
   * This method will handle the QR scan event
   * @param {string} value
   */
  function onQRScan(value) {
    if (pageName === "visitor_type") {
      return;
    }

    updatePage("registration_code", {
      registrationCode: value,
    });
  }

  return <FrontofficePageView />;
}

export default FrontofficePage;
