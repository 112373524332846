import { useTheme } from "styled-components";
import { useFrontofficeContext } from "../../../../context/FrontofficeContext";
import { useLanguageContext } from "../../../../context/LanguageContext";
import Layout from "../../../../components/layout/Layout";
import {
  StyledFlexColumn,
  StyledFlexItem,
  StyledFlexRow,
} from "../../../../styles/LayoutStyles";
import { StyledInput } from "../../../../styles/InputStyles";
import InputWrapper from "../../../../components/input/wrapper/InputWrapper";
import { StyledAltButton, StyledButton } from "../../../../styles/ButtonStyles";
import HiddenLoader from "../../../../components/loader/HiddenLoader/HiddenLoader";
import EVAApiHelper from "../../../../helpers/EVAApiHelper";

function RegistrationCodePageView({
  registrationCode,
  onRegistrationCodeChange,
  onProceed,
  error,
}) {
  const theme = useTheme();
  const { updatePage, terminal } = useFrontofficeContext();
  const { translate } = useLanguageContext();

  return (
    <Layout
      layoutData={terminal?.layout}
      content={
        <StyledFlexColumn style={{ gap: theme.spacing.large }}>
          <InputWrapper
            label={translate(
              "eva.frontoffice.pages.registration_code.fields.registration_code"
            )}
            required
            error={EVAApiHelper.getResponseError("message", error, true)}
          >
            <StyledInput
              value={registrationCode}
              placeholder={translate(
                "eva.frontoffice.pages.registration_code.fields.registration_code"
              )}
              onChange={onRegistrationCodeChange}
            />
          </InputWrapper>

          <StyledFlexRow>
            <StyledFlexItem>
              <StyledAltButton
                onClick={() => updatePage("start")}
                style={{ width: "100%" }}
              >
                {translate("eva.frontoffice.general.cancel")}
              </StyledAltButton>
            </StyledFlexItem>

            <StyledFlexItem>
              <HiddenLoader groups={"qr_sign_in_fetch"}>
                <StyledButton
                  onClick={() => onProceed()}
                  disabled={registrationCode.length <= 0}
                  style={{ width: "100%" }}
                >
                  {translate("eva.frontoffice.general.proceed")}
                </StyledButton>
              </HiddenLoader>
            </StyledFlexItem>
          </StyledFlexRow>
        </StyledFlexColumn>
      }
    />
  );
}

export default RegistrationCodePageView;
