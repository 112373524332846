import FieldsStepView from "./FieldsStepView";

function FieldsStep({ step, errors, visitorData, setVisitorData }) {
  return (
    <FieldsStepView
      step={step}
      errors={errors}
      visitorData={visitorData}
      setVisitorData={setVisitorData}
    />
  );
}

export default FieldsStep;
