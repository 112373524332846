import InputWrapper from "../../../../../../components/input/wrapper/InputWrapper";
import HiddenLoader from "../../../../../../components/loader/HiddenLoader/HiddenLoader";
import Select from "../../../../../../components/select/main/Select";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import EVAApiHelper from "../../../../../../helpers/EVAApiHelper";

function EmployeeStepView({
  errors,
  visitorData,
  employees,
  onEmployeeIdChange,
}) {
  const { translate } = useLanguageContext();

  return (
    <HiddenLoader groups={"employee_step_fetch"}>
      <InputWrapper
        label={translate(
          "eva.frontoffice.pages.visitor_type.steps.employee.fields.employee"
        )}
        error={EVAApiHelper.getResponseError("employee_id", errors)}
        required={true}
      >
        <Select
          value={visitorData.employee_id}
          options={
            employees
              ? employees.map((emp) => ({ value: emp.id, label: emp.name }))
              : []
          }
          onChange={onEmployeeIdChange}
        />
      </InputWrapper>
    </HiddenLoader>
  );
}

export default EmployeeStepView;
