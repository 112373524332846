import { useState } from "react";
import { useLanguageContext } from "../../../context/LanguageContext";
import {
  faCheck,
  faFileExport,
  faFileImport,
  faPen,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { StyledInput } from "../../../styles/InputStyles";
import { useAuthContext } from "../../../context/AuthContext";
import FileHelper from "../../../helpers/FileHelper";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";
import DataTableConfig from "../main/config/dataTable/DataTableConfig";
import useBasicColumnConfig from "../main/config/column/default/BasicColumnConfig";
import RowActionConfig from "../main/config/rowAction/RowActionConfig";
import HeaderActionConfig from "../main/config/headerAction/HeaderActionConfig";
import DataTable, { useDataTable } from "../main/DataTable";
import useTimestampColumnConfig from "../main/config/column/default/TimestampColumnConfig";

function TranslationsDataTable({ languageData }) {
  const callEvaApi = useEvaApi();
  const authContext = useAuthContext();
  const { translate, fetchTranslations, language } = useLanguageContext();
  const [editRow, setEditRow] = useState(null);

  //Create the datatable config
  const config = new DataTableConfig("translations");

  //Set the datatable header
  config.setHeader(translate("eva.main.entities.translations"));

  //Set the datatable fetch info
  config.setFetchRequest(`translations/${languageData.id}`);
  config.setFetchParams({
    where:
      authContext.auth.user.company.type === "superadmin"
        ? []
        : [
            {
              column: "key",
              values: [{ value: "eva.frontoffice" }],
            },
          ],
  });

  //Set the datatable order by
  config.setOrderBy("key");
  config.setOrderDirection("asc");

  //set the datatable columns
  config.setColumns([
    useBasicColumnConfig("key").setLockActive(true),
    useBasicColumnConfig("value")
      .setLockActive(true)
      .setData(getValueColumnData),
    useTimestampColumnConfig("updated_at"),
  ]);

  //Set the datatable row actions
  config.setRowActions([
    new RowActionConfig()
      .setIcon(faPen)
      .setCallback(handleEdit)
      .setHidden((row) => row.id === editRow?.id)
      .setTitle((row) =>
        translate("eva.main.entities.states.edit", [
          {
            key: "entities",
            value: translate("eva.main.entities.translation"),
          },
        ])
      ),
    new RowActionConfig()
      .setIcon(faXmark)
      .setCallback(handleCancel)
      .setHidden((row) => row.id !== editRow?.id)
      .setTitle(translate("eva.main.general.cancel")),
    new RowActionConfig()
      .setIcon(faCheck)
      .setCallback(handleConfirm)
      .setHidden((row) => row.id !== editRow?.id)
      .setTitle(translate("eva.main.general.save")),
  ]);

  //Set the datatable header actions
  config.setHeaderActions([
    new HeaderActionConfig()
      .setIcon(faFileExport)
      .setCallback(handleExport)
      .setTitle(
        translate("eva.main.entities.states.export", [
          {
            key: "entities",
            value: translate("eva.main.entities.translations"),
          },
        ])
      )
      .setLoadingGroups(`${config.getDataTableKey()}_export`),
    new HeaderActionConfig()
      .setIcon(faFileImport)
      .setCallback(handleImport)
      .setTitle(
        translate("eva.main.entities.states.import", [
          {
            key: "entities",
            value: translate("eva.main.entities.translations"),
          },
        ])
      )
      .setLoadingGroups(`${config.getDataTableKey()}_import`),
  ]);

  //Set the overall loading groups for the datatable row actions
  config.setRowActionsLoadingGroups(
    (row) => `${config.getDataTableKey()}_update_${row.id}`
  );

  //Create the datatable object
  const dataTable = useDataTable(config);

  /**
   * This method will return the visual representation of the value column
   * @param {object} row
   * @returns {string|any}
   */
  function getValueColumnData(row) {
    if (row.id !== editRow?.id) {
      return row.value;
    } else {
      return (
        <StyledInput
          as={"textarea"}
          value={editRow.value}
          width={"100%"}
          onChange={(e) => setEditRow({ ...editRow, value: e.target.value })}
        />
      );
    }
  }

  /**
   * This method will start the edit state of the given row
   * @param {object} row
   */
  function handleEdit(row) {
    setEditRow(row);
  }

  /**
   * This method will cancel the update of the given row
   * @param {object} row
   */
  function handleCancel(row) {
    setEditRow(null);
  }

  /**
   * This method will send a request to the API for updating the given row
   * @param {object} row
   */
  async function handleConfirm(row) {
    if (editRow.value === row.value) {
      setEditRow(null);
      return;
    }

    await callEvaApi(
      new EvaApiCall(`translations/${languageData.id}/${row.id}`)
        .setLoadingGroup(`${config.getDataTableKey()}_update_${row.id}`)
        .setMethod("PUT")
        .setData({ value: editRow.value })
        .setOnSuccess((response) => {
          row.value = response.data.data.value;
          setEditRow(null);

          dataTable.fetch();

          //Update the app translations if the app language has been updated (main translations only)
          if (
            parseInt(languageData.id) === parseInt(language.id) &&
            row.key.includes("eva.main")
          ) {
            fetchTranslations();
          }
        })
    );
  }

  /**
   * This method will try and add a new record
   */
  async function handleExport() {
    await callEvaApi(
      new EvaApiCall(`translations/${languageData.id}/export`)
        .setLoadingGroup(`${config.getDataTableKey()}_export`)
        .setDownloadAs(`${languageData.code}_translations`)
        .setParams({
          ...dataTable.filters,
          frontoffice_only: authContext.auth.user.company.type !== "superadmin",
        })
    );
  }

  /**
   * This method will try and add a new record
   */
  async function handleImport() {
    const selectedFile = await FileHelper.select(".xlsx");
    if (!selectedFile) {
      return;
    }

    await callEvaApi(
      new EvaApiCall(`translations/${languageData.id}/import`)
        .setMethod("POST")
        .setLoadingGroup(`${config.getDataTableKey()}_import`)
        .setData({ file: selectedFile })
        .setOnSuccess(() => {
          dataTable.fetch();
        })
    );
  }

  return <DataTable controller={dataTable} />;
}

export default TranslationsDataTable;
