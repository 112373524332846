import { Navigate, Route, useParams } from "react-router-dom";
import { useRouteContext } from "../../context/RouteContext";
import useCustomEffect from "../../hooks/useCustomEffect";

export class RouteConfig {
  constructor() {
    this.path = "";
    this.label = "";
    this.validator = null;
    this.page = null;
    this.parent = null;
    this.onPreviousStep = null;
    this.pathSteps = [];
  }

  setPath(path) {
    this.path = path;
    return this;
  }

  setLabel(label) {
    this.label = label;
    return this;
  }

  setValidator(validator) {
    this.validator = validator;
    return this;
  }

  setPage(page) {
    this.page = page;
    return this;
  }

  setParent(parent) {
    this.parent = parent;
    return this;
  }

  getRoute() {
    return (
      <Route
        path={this.path}
        element={<RouteConfigElement routeConfig={this} />}
      />
    );
  }

  getPathParamNames() {
    const pathParts = this.path.split("/");
    const paramNames = pathParts
      .filter((part) => part.startsWith(":"))
      .map((part) => part.replace(":", ""));
    return paramNames;
  }

  getPathWithParams(params) {
    const paramNames = this.getPathParamNames();
    let pathFromUrl = this.path.slice();
    paramNames.forEach((param) => {
      pathFromUrl = pathFromUrl.replace(`:${param}`, params[param]);
    });
    return pathFromUrl;
  }

  /**
   * This method will return the step of the parent route.
   * @param {object} params
   * @returns {{ path, label } | null}
   */
  getPreviousStep(params) {
    return this.parent
      ? {
          path: this.parent.getPathWithParams(params),
          label: this.parent.label,
        }
      : null;
  }

  /**
   * This method will return the path steps from the root route to the current route.
   * @param {object} params
   * @param {boolean} reverse If true, the path list will be reversed.
   * @returns {{ path, label }[]}
   */
  getPathSteps(params, reverse = true) {
    let steps = [
      {
        path: this.getPathWithParams(params),
        label: this.label,
      },
    ];
    if (this.parent) {
      steps = [...steps, ...this.parent.getPathSteps(params, false)];
    }
    return reverse ? steps.reverse() : steps;
  }
}

export function RouteConfigElement({ routeConfig }) {
  const routeContext = useRouteContext();
  const params = useParams();
  const validation = routeConfig.validator
    ? routeConfig.validator.validate()
    : true;

  useCustomEffect(() => {
    routeConfig.onPreviousStep = routeConfig.getPreviousStep(params);
    routeConfig.pathSteps = routeConfig.getPathSteps(params);
    routeContext.updateRouteConfig(routeConfig);
  }, [params, routeConfig]);

  if (validation === true) {
    return (
      routeConfig.path === routeContext.routeConfig?.path && routeConfig.page
    );
  } else {
    return <Navigate to={validation.to} replace={validation.replace} />;
  }
}
