import Layout from "../../../../components/layout/Layout";
import HiddenLoader from "../../../../components/loader/HiddenLoader/HiddenLoader";
import { useFrontofficeContext } from "../../../../context/FrontofficeContext";
import { useLanguageContext } from "../../../../context/LanguageContext";
import { useLoadContext } from "../../../../context/LoadContext";
import { StyledAltButton, StyledButton } from "../../../../styles/ButtonStyles";
import { StyledFlexItem, StyledFlexRow } from "../../../../styles/LayoutStyles";
import { getStep } from "./steps/Steps";
import { StyledVisitorTypePage } from "./VisitorTypePageStyles";

function VisitorTypePageView({
  layout,
  step,
  errors,
  visitorData,
  setVisitorData,
  disabledNavItems,
  setDisabledNavItems,
  onNextStep,
  onPreviousStep,
}) {
  const { translate } = useLanguageContext();
  const { updatePage } = useFrontofficeContext();
  const { isLoading } = useLoadContext();
  const StepComponent = getStep(step?.type);

  return (
    <Layout
      layoutData={layout}
      content={
        <HiddenLoader groups={"next_step"} iconSize={"lg"}>
          <StyledVisitorTypePage key={step?.id}>
            {/* Step Component */}
            {StepComponent && (
              <StepComponent
                key={step.id}
                step={step}
                errors={errors}
                visitorData={visitorData}
                setVisitorData={setVisitorData}
                setDisabledNavItems={setDisabledNavItems}
              />
            )}

            <HiddenLoader groups={"visitor_type_complete"}>
              <StyledFlexRow>
                {/* Cancel Button */}
                <StyledFlexItem>
                  <StyledAltButton
                    onClick={() => updatePage("start")}
                    disabled={
                      isLoading([
                        "visitor_type_next_step",
                        "visitor_type_previous_step",
                      ]) || disabledNavItems.includes("cancel")
                    }
                    style={{ width: "100%" }}
                  >
                    {translate("eva.frontoffice.general.cancel")}
                  </StyledAltButton>
                </StyledFlexItem>

                {/* Back Button */}
                {step?.order > 0 && (
                  <StyledFlexItem>
                    <HiddenLoader groups={"visitor_type_previous_step"}>
                      <StyledAltButton
                        onClick={onPreviousStep}
                        disabled={
                          isLoading("visitor_type_next_step") ||
                          disabledNavItems.includes("previous")
                        }
                        style={{ width: "100%" }}
                      >
                        {translate("eva.frontoffice.general.back")}
                      </StyledAltButton>
                    </HiddenLoader>
                  </StyledFlexItem>
                )}

                {/* Proceed Button */}
                <StyledFlexItem>
                  <HiddenLoader groups={"visitor_type_next_step"}>
                    <StyledButton
                      onClick={onNextStep}
                      disabled={
                        isLoading("visitor_type_previous_step") ||
                        disabledNavItems.includes("next")
                      }
                      style={{ width: "100%" }}
                    >
                      {translate("eva.frontoffice.general.proceed")}
                    </StyledButton>
                  </HiddenLoader>
                </StyledFlexItem>
              </StyledFlexRow>
            </HiddenLoader>
          </StyledVisitorTypePage>
        </HiddenLoader>
      }
    />
  );
}

export default VisitorTypePageView;
