import FilePreview from "../../../../../../components/filePreview/FilePreview";
import InputWrapper from "../../../../../../components/input/wrapper/InputWrapper";
import Select from "../../../../../../components/select/main/Select";
import { useLanguageContext } from "../../../../../../context/LanguageContext";
import EVAApiHelper from "../../../../../../helpers/EVAApiHelper";
import FileHelper from "../../../../../../helpers/FileHelper";
import {
  StyledFlexColumn,
  StyledFlexRow,
} from "../../../../../../styles/LayoutStyles";

function QuestionnaireStepView({
  questionnaire,
  visitorData,
  onQuestionChange,
  errors,
}) {
  const { translateEntity } = useLanguageContext();

  function renderQuestion(question) {
    const value = visitorData[`question_${question.id}`];

    return (
      <InputWrapper
        key={question.id}
        required
        label={translateEntity(question, "question")}
        error={EVAApiHelper.getResponseError(`question_${question.id}`, errors)}
      >
        <StyledFlexRow style={{ alignItems: "center" }}>
          {question.image && (
            <FilePreview
              path={FileHelper.apiPath(translateEntity(question.image, "path"))}
            />
          )}

          <Select
            value={value}
            onChange={(val) => onQuestionChange(question, val)}
            options={question.answers.map((answer) => ({
              value: answer.id,
              label: translateEntity(answer, "answer"),
            }))}
          />
        </StyledFlexRow>
      </InputWrapper>
    );
  }

  return (
    <StyledFlexColumn>
      {questionnaire.questions.map((question) => renderQuestion(question))}
    </StyledFlexColumn>
  );
}

export default QuestionnaireStepView;
