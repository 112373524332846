import ButtonPage from "./button/ButtonPage";
import RegistrationCodePage from "./registration_code/RegistrationCodePage";
import SignInPage from "./sign_in/SignInPage";
import ManualSignOutPage from "./sign_out/manual/ManualSignOutPage";
import SignOutPage from "./sign_out/SignOutPage";
import StartPage from "./start/StartPage";
import VisitorTypePage from "./visitor_type/VisitorTypePage";

const pages = {
  start: StartPage,
  sign_in: SignInPage,
  registration_code: RegistrationCodePage,
  sign_out: SignOutPage,
  manual_sign_out: ManualSignOutPage,
  visitor_type: VisitorTypePage,
  button: ButtonPage,
};

export function getPage(name) {
  return pages[name];
}
