import styled from "styled-components";
import { StyledButton } from "../../../../../styles/ButtonStyles";

export const StyledSignoutButton = styled(StyledButton)`
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  & span {
    font-weight: normal;
  }
`;
