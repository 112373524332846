import { useState } from "react";
import DashboardPageView from "./DashboardPageView";
import { useAuthContext } from "../../../context/AuthContext";
import PresentEmployeesStatistic from "./statistics/present_employees/PresentEmployeesStatistic";

function DashboardPage() {
  const authContext = useAuthContext();
  const [statistics, setStatistics] = useState(getStatistics());

  /**
   * This function will get the statistics from the local storage
   * @returns {Object} statistics
   */
  function getStatistics() {
    const storageStatistics = JSON.parse(
      localStorage.getItem("dashboard_statistics")
    );

    return {
      present_employees: {
        visible: storageStatistics?.present_employees ?? true,
        disabled: authContext.auth.user?.company.type === "superadmin",
        component: <PresentEmployeesStatistic />,
      },
    };
  }

  return (
    <DashboardPageView statistics={statistics} setStatistics={setStatistics} />
  );
}

export default DashboardPage;
