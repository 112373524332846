import { useNavigate } from "react-router-dom";
import useContextDefiner, {
  ContextDefinition,
} from "../hooks/useContextDefiner";
import { createContext, useContext, useState } from "react";

const RouteContext = createContext();

export function RouteProvider({ children }) {
  const navigate = useNavigate();
  const [routeConfig, setCurrentRouteConfig] = useState(null);

  /**
   * This method will update the current route config
   * @param {object} newRouteConfig
   */
  function updateRouteConfig(newRouteConfig) {
    if (routeConfig?.path === newRouteConfig.path) {
      return;
    }
    setCurrentRouteConfig(newRouteConfig);
  }

  /**
   * This method will navigate back to the previous step if it exists
   */
  function back() {
    if (routeConfig?.onPreviousStep?.path) {
      navigate(routeConfig.onPreviousStep.path);
    }
  }

  return useContextDefiner(
    new ContextDefinition("routeContext", RouteContext)
      .setData({ routeConfig, updateRouteConfig, back })
      .setChildren(children)
  );
}

export function useRouteContext() {
  return useContext(RouteContext);
}
