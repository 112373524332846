import { useFrontofficeContext } from "../../../../context/FrontofficeContext";
import ButtonPageView from "./ButtonPageView";

function ButtonPage() {
  const { pageParams, terminal } = useFrontofficeContext();
  const button = terminal?.buttons?.find(
    (button) => parseInt(button.id) === parseInt(pageParams?.buttonId)
  );
  const layout = button?.layout || terminal?.layout;

  return <ButtonPageView button={button} layout={layout} />;
}

export default ButtonPage;
