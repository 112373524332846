import { useState } from "react";
import useEvaApi, { EvaApiCall } from "../../../../hooks/useEvaApi";
import useTimestampColumnConfig from "../../main/config/column/default/TimestampColumnConfig";
import DataTableConfig from "../../main/config/dataTable/DataTableConfig";
import useDeleteRowActionConfig from "../../main/config/rowAction/default/DeleteRowActionConfig";
import DataTable, { useDataTable } from "../../main/DataTable";
import useCustomEffect from "../../../../hooks/useCustomEffect";
import ColumnConfig from "../../main/config/column/ColumnConfig";
import { useLanguageContext } from "../../../../context/LanguageContext";
import useBasicColumnConfig from "../../main/config/column/default/BasicColumnConfig";
import useEditRowActionConfig from "../../main/config/rowAction/default/EditRowActionConfig";
import useVisitorEditRouteConfig from "../../../../routes/configs/visitors/VisitorEditRouteConfig";
import useCreateHeaderActionConfig from "../../main/config/headerAction/default/CreateHeaderActionConfig";
import useVisitorCreateRouteConfig from "../../../../routes/configs/visitors/VisitorCreateRouteConfig";
import RowActionConfig from "../../main/config/rowAction/RowActionConfig";
import { faSignIn, faSignOut } from "@fortawesome/free-solid-svg-icons";
import { useAuthContext } from "../../../../context/AuthContext";

function VisitorsDataTable() {
  const callEvaApi = useEvaApi();
  const authContext = useAuthContext();
  const { translate, translateEntity } = useLanguageContext();
  const [fields, setFields] = useState([]);

  //Create the datatable config
  const config = new DataTableConfig("visitors");

  //Set the datatable fetch info
  config.setFetchRequest("visitors");
  config.setFetchParams({
    with_visitor_fields_data: true,
    with_visitor_visitor_type_data: true,
    with_visitor_employee_data: true,
    with_visitor_location_data: true,
    with_visitor_sign_in_terminal_data: true,
  });

  //Set default order
  config.setOrderBy("id");
  config.setOrderDirection("asc");

  //Set the datatable columns
  config.setColumns([
    useBasicColumnConfig("location.name"),
    useBasicColumnConfig("visitor_type.name.translation").setData((row) =>
      translateEntity(row.visitor_type, "title")
    ),
    useBasicColumnConfig("employee.name"),
    useBasicColumnConfig("registration_code"),
    useBasicColumnConfig("status.translation").setData((row) =>
      translate("eva.main.visitor_statuses." + row.status)
    ),

    ...fields.map((field) =>
      new ColumnConfig()
        .setKey(
          `field_${field.id}.${
            field.type === "select" ? "option.translation" : "value"
          }`
        )
        .setLabel(translateEntity(field, "label"))
        .setData((row) => getFieldColumnData(field, row))
        .setSearchable(field.type !== "boolean")
    ),

    useBasicColumnConfig("expected_date"),
    useBasicColumnConfig("expected_time"),
    useBasicColumnConfig("sign_in_terminal.name"),
    useBasicColumnConfig("signed_in_at"),
    useTimestampColumnConfig("created_at"),
    useTimestampColumnConfig("updated_at"),
  ]);

  //Set the datatable row actions
  config.setRowActions([
    new RowActionConfig()
      .setIcon(faSignIn)
      .setDisabled(!authContext.hasPermission("visitors.sign_in"))
      .setCallback(onSignIn)
      .setHidden((row) => row.signed_in_at)
      .setLoadingGroups((row) => `visitor_sign_in_${row.id}`)
      .setTitle(
        translate("eva.main.entities.states.sign_in", [
          { key: "entities", value: translate("eva.main.entities.visitor") },
        ])
      ),

    new RowActionConfig()
      .setIcon(faSignOut)
      .setDisabled(!authContext.hasPermission("visitors.sign_out"))
      .setCallback(onSignOut)
      .setHidden((row) => !row.signed_in_at)
      .setLoadingGroups((row) => `visitor_sign_out_${row.id}`)
      .setTitle(
        translate("eva.main.entities.states.sign_out", [
          { key: "entities", value: translate("eva.main.entities.visitor") },
        ])
      ),

    useEditRowActionConfig("visitor", useVisitorEditRouteConfig()),
    useDeleteRowActionConfig("visitor").setHidden((row) => row.signed_in_at),
  ]);

  //Set the datatable header actions
  config.setHeaderActions([
    useCreateHeaderActionConfig("visitor", useVisitorCreateRouteConfig()),
  ]);

  //Create the datatable object
  const dataTable = useDataTable(config);

  /**
   * This effect will fetch the fields from the API
   */
  useCustomEffect(() => {
    fetchFields();
  });

  /**
   * This method will fetch the fields from the API
   */
  async function fetchFields() {
    await callEvaApi(
      new EvaApiCall("fields")
        .setLoadingGroup(config.getFetchKey())
        .setParams({ with_field_options_data: true })
        .setAlertError(true)
        .setAlertSuccess(false)
        .setOnSuccess((response) => {
          setFields(response.data.data.records);
        })
    );
  }

  /**
   * This method will return the column data for the given field and row
   * @param {object} field
   * @param {object} row
   * @returns {any}
   */
  function getFieldColumnData(field, row) {
    // Find the field data for the given field
    const fieldData = row.fields?.find((f) => f.field === field.id);
    if (!fieldData) {
      return "";
    }

    // If the field is a select, return the translation of the selected option
    if (field.type === "select") {
      const option = field.options.find(
        (o) => o.id === fieldData?.field_option
      );
      return option ? translateEntity(option, "label") : "";
    }
    // If the field is a boolean, return the translation of the value
    else if (field.type === "boolean") {
      return fieldData?.value === "1"
        ? translate("eva.main.general.yes")
        : translate("eva.main.general.no");
    }

    // Otherwise, return the value of the field
    return fieldData?.value ?? "";
  }

  /**
   * This method will handle the sign in action
   * @param {object} row
   */
  async function onSignIn(row) {
    await callEvaApi(
      new EvaApiCall(`visitors/sign_in/${row.id}/simplified`)
        .setMethod("POST")
        .setLoadingGroup(`visitor_sign_in_${row.id}`)
        .setAlertError(true)
        .setAlertSuccess(true)
        .setOnSuccess(() => dataTable.fetch())
    );
  }

  /**
   * This method will handle the sign in action
   * @param {object} row
   */
  async function onSignOut(row) {
    await callEvaApi(
      new EvaApiCall(`visitors/sign_out/${row.id}/simplified`)
        .setMethod("POST")
        .setLoadingGroup(`visitor_sign_out_${row.id}`)
        .setAlertError(true)
        .setAlertSuccess(true)
        .setOnSuccess(() => dataTable.fetch())
    );
  }

  return <DataTable controller={dataTable} />;
}

export default VisitorsDataTable;
