import { useRef } from "react";
import useCustomEffect from "./useCustomEffect";

function useScannerListener(listener = (value) => {}) {
  const bufferRef = useRef("");
  const bufferResetRef = useRef(null);
  const listenerRef = useRef(listener);

  /**
   * This effect will add the keydown event listener
   */
  useCustomEffect(() => {
    document.addEventListener("keypress", onKeyPress);
    return () => {
      document.removeEventListener("keypress", onKeyPress);
    };
  });

  /**
   * this method will handle the keydown event
   * @param {event} event
   */
  function onKeyPress(event) {
    if (event.key === "Enter" && bufferRef.current.length > 0) {
      listenerRef.current(bufferRef.current);
      clearResetTimer();
      bufferRef.current = "";
    } else {
      bufferRef.current += event.key;
      startResetTimer();
    }
  }

  /**
   * This method will start the reset timer (needed to avoid normal keyboard input as scanner input)
   */
  function startResetTimer() {
    clearResetTimer();
    bufferResetRef.current = setTimeout(() => {
      bufferRef.current = "";
    }, 50);
  }

  /**
   * This method will clear the reset timer
   */
  function clearResetTimer() {
    clearTimeout(bufferResetRef.current);
  }
}

export default useScannerListener;
