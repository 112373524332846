import { useTheme } from "styled-components";
import Layout from "../../../../components/layout/Layout";
import { useLanguageContext } from "../../../../context/LanguageContext";
import { StyledAltButton, StyledButton } from "../../../../styles/ButtonStyles";
import { StyledFlexColumn } from "../../../../styles/LayoutStyles";
import { useFrontofficeContext } from "../../../../context/FrontofficeContext";

function SignOutPageView() {
  const theme = useTheme();
  const { updatePage, terminal } = useFrontofficeContext();
  const { translate } = useLanguageContext();

  return (
    <Layout
      layoutData={terminal?.layout}
      content={
        <StyledFlexColumn style={{ gap: theme.spacing.large }}>
          <StyledFlexColumn>
            <StyledButton onClick={() => updatePage("registration_code")}>
              {translate(
                "eva.frontoffice.pages.registration_code.label_sign_out"
              )}
            </StyledButton>

            <StyledButton onClick={() => updatePage("manual_sign_out")}>
              {translate("eva.frontoffice.pages.manual_sign_out.label")}
            </StyledButton>
          </StyledFlexColumn>

          <StyledAltButton onClick={() => updatePage("start")}>
            {translate("eva.frontoffice.general.back")}
          </StyledAltButton>
        </StyledFlexColumn>
      }
    />
  );
}

export default SignOutPageView;
