import { useTheme } from "styled-components";
import Layout from "../../../../components/layout/Layout";
import { useLanguageContext } from "../../../../context/LanguageContext";
import { StyledFlexColumn } from "../../../../styles/LayoutStyles";
import { StyledAltButton, StyledButton } from "../../../../styles/ButtonStyles";
import { useFrontofficeContext } from "../../../../context/FrontofficeContext";

function StartPageView() {
  const theme = useTheme();
  const { updatePage, terminal } = useFrontofficeContext();
  const { translateEntity, translate } = useLanguageContext();

  return (
    <Layout
      layoutData={terminal?.layout}
      content={
        <StyledFlexColumn style={{ gap: theme.spacing.large }}>
          <StyledFlexColumn>
            <StyledButton onClick={() => updatePage("sign_in")}>
              {translate("eva.frontoffice.pages.sign_in.label")}
            </StyledButton>

            <StyledButton onClick={() => updatePage("sign_out")}>
              {translate("eva.frontoffice.pages.sign_out.label")}
            </StyledButton>
          </StyledFlexColumn>

          {terminal?.buttons?.length > 0 && (
            <StyledFlexColumn>
              {terminal?.buttons
                ?.sort((a, b) => a.order - b.order)
                ?.map((button) => (
                  <StyledAltButton
                    key={button.id}
                    onClick={() =>
                      updatePage("button", { buttonId: button.id })
                    }
                  >
                    {translateEntity(button, "title")}
                  </StyledAltButton>
                ))}
            </StyledFlexColumn>
          )}
        </StyledFlexColumn>
      }
    />
  );
}

export default StartPageView;
