import { useFrontofficeContext } from "../../context/FrontofficeContext";
import { Themed } from "../../context/ThemeContext";
import { StyledFrontOfficePage } from "./FrontOfficePageStyles";
import { getPage } from "./pages/Pages";
import Screensaver from "./screensaver/Screensaver";
import Settings from "./settings/Settings";

function FrontofficePageView() {
  const { pageName, updatePage, terminal } = useFrontofficeContext();
  const PageComponent = getPage(pageName);

  return (
    <Themed frontoffice={true}>
      <StyledFrontOfficePage>
        {terminal && (
          <>
            {PageComponent && <PageComponent />}

            <Screensaver
              screensavers={terminal.screensavers}
              onActivation={() => updatePage("start")}
            />
          </>
        )}

        <Settings />
      </StyledFrontOfficePage>
    </Themed>
  );
}

export default FrontofficePageView;
