import packageJson from "../../../package.json";
import { StyledVersion } from "./VersionStyles";

function VersionView({ style, fixed }) {
  return (
    <StyledVersion style={style} fixed={fixed}>
      v{packageJson.version}
    </StyledVersion>
  );
}

export default VersionView;
