import EmployeeStep from "./employee/EmployeeStep";
import FieldsStep from "./fields/FieldsStep";
import HouseruleStep from "./houserule/HouseruleStep";
import QuestionnaireStep from "./questionnaire/QuestionnaireStep";

export const steps = {
  fields: FieldsStep,
  questionnaire: QuestionnaireStep,
  houserule: HouseruleStep,
  employee: EmployeeStep,
};

export function getStep(type) {
  return steps[type];
}
