import { useTheme } from "styled-components";
import InputWrapper from "../../../components/input/wrapper/InputWrapper";
import Modal from "../../../components/modal/Modal";
import Select from "../../../components/select/main/Select";
import { useLanguageContext } from "../../../context/LanguageContext";
import { StyledAltButton } from "../../../styles/ButtonStyles";
import { StyledFlexColumn } from "../../../styles/LayoutStyles";
import { useAuthContext } from "../../../context/AuthContext";
import { Themed } from "../../../context/ThemeContext";
import { useFrontofficeContext } from "../../../context/FrontofficeContext";
import Version from "../../../components/version/Version";

function SettingsView({ open, onClose }) {
  const theme = useTheme();
  const { terminal, terminals, updateTerminal } = useFrontofficeContext();
  const authContext = useAuthContext();
  const { translate } = useLanguageContext();

  return (
    open && (
      <Themed frontoffice={false}>
        <Modal
          title={translate("eva.main.general.settings")}
          onClose={terminal ? onClose : null}
        >
          <StyledFlexColumn>
            <InputWrapper
              label={translate("eva.main.validation.attributes.terminal_id")}
              required
            >
              <Select
                options={terminals.map((terminal) => ({
                  value: terminal.id,
                  label: terminal.name,
                }))}
                value={terminal?.id}
                onChange={updateTerminal}
              />
            </InputWrapper>

            <StyledAltButton
              onClick={() => authContext.logout()}
              style={{ marginTop: theme.spacing.normal }}
            >
              {translate("eva.main.auth.logout")}
            </StyledAltButton>
          </StyledFlexColumn>

          <Version fixed={true} />
        </Modal>
      </Themed>
    )
  );
}

export default SettingsView;
