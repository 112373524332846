import { useParams } from "react-router-dom";
import { useState } from "react";
import TerminalPageView from "./TerminalPageView";
import useCustomEffect from "../../../hooks/useCustomEffect";
import { useRouteContext } from "../../../context/RouteContext";
import { useAuthContext } from "../../../context/AuthContext";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";

function TerminalPage() {
  const callEvaApi = useEvaApi();
  const routeContext = useRouteContext();
  const authContext = useAuthContext();
  const { terminal } = useParams();
  const [submitError, setSubmitError] = useState(null);
  const [buttons, setButtons] = useState([]);
  const [layouts, setLayouts] = useState([]);
  const [visitorTypes, setVisitorTypes] = useState([]);
  const [screensavers, setScreensavers] = useState([]);
  const [terminalData, setTerminalData] = useState({
    name: "",
    location_id: authContext.auth.location,
    layout_id: null,
    buttons: [],
    visitor_types: [],
    screensavers: [],
  });

  /**
   * initialize the page
   */
  useCustomEffect(() => {
    if (terminal) {
      fetchTerminalData();
    }

    fetchButtons();
    fetchVisitorTypes();
    fetchScreensavers();
    fetchLayouts();
  });

  /**
   * This method will fetch the terminal data from the API
   */
  async function fetchTerminalData() {
    await callEvaApi(
      new EvaApiCall(`terminals/${terminal}`)
        .setLoadingGroup("terminal_page_fetch")
        .setOnSuccess((response) => {
          setTerminalData({
            name: response.data.data.name,
            location_id: response.data.data.location,
            layout_id: response.data.data.layout,
            buttons: response.data.data.buttons,
            visitor_types: response.data.data.visitor_types,
            screensavers: response.data.data.screensavers,
          });
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will fetch the button data from API
   */
  async function fetchButtons() {
    await callEvaApi(
      new EvaApiCall("buttons")
        .setLoadingGroup("terminal_page_fetch")
        .setOnSuccess((response) => {
          setButtons(response.data.data.records);
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will fetch the visitor type data from API
   */
  async function fetchVisitorTypes() {
    await callEvaApi(
      new EvaApiCall("visitor_types")
        .setLoadingGroup("terminal_page_fetch")
        .setOnSuccess((response) => {
          setVisitorTypes(response.data.data.records);
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will fetch the screensavers from API
   */
  async function fetchScreensavers() {
    await callEvaApi(
      new EvaApiCall("files")
        .setParams({
          where: [{ column: "type", values: ["image"] }],
        })
        .setLoadingGroup("terminal_page_fetch")
        .setOnSuccess((response) => {
          setScreensavers(response.data.data.records);
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will fetch the layouts from API
   */
  async function fetchLayouts() {
    await callEvaApi(
      new EvaApiCall("layouts")
        .setLoadingGroup("terminal_page_fetch")
        .setOnSuccess((response) => {
          setLayouts(response.data.data.records);
        })
        .setRedirectOnError(true)
    );
  }

  /**
   * This method will update the buttons on input change
   * @param {array} values
   */
  function onButtonsChange(values) {
    setTerminalData({
      ...terminalData,
      buttons: values,
    });
  }

  /**
   * This method will update the visitor types on input change
   * @param {array} values
   */
  function onVisitorTypesChange(values) {
    setTerminalData({
      ...terminalData,
      visitor_types: values,
    });
  }

  /**
   * This method will update the screensavers on input change
   * @param {array} values
   */
  function onScreensaversChange(values) {
    setTerminalData({
      ...terminalData,
      screensavers: values,
    });
  }

  /**
   * This method will update the name on input change
   */
  function onNameChange(e) {
    setTerminalData((current) => ({ ...current, name: e.target.value }));
  }

  /**
   * This method will update the name on input change
   */
  function onLocationIdChange(value) {
    setTerminalData((current) => ({ ...current, location_id: value }));
  }

  /**
   * This method will update the layout id on input change
   * @param {any} value
   */
  function onLayoutIdChange(value) {
    setTerminalData((current) => ({ ...current, layout_id: value }));
  }

  /**
   * This method will handle the onCancel functionality
   */
  function onCancel() {
    routeContext.back();
  }

  /**
   * This method will submit the form to the EVA API and handles its response
   */
  async function onSubmit() {
    await callEvaApi(
      new EvaApiCall(terminal ? `terminals/${terminal}` : "terminals")
        .setLoadingGroup("terminal_page_save")
        .setMethod(terminal ? "PUT" : "POST")
        .setData(terminalData)
        .setErrorState(setSubmitError)
        .setRedirectOnSuccess(true)
    );
  }

  return (
    <TerminalPageView
      terminal={terminal}
      terminalData={terminalData}
      buttons={buttons}
      visitorTypes={visitorTypes}
      screensavers={screensavers}
      layouts={layouts}
      onButtonsChange={onButtonsChange}
      onNameChange={onNameChange}
      onLocationIdChange={onLocationIdChange}
      onLayoutIdChange={onLayoutIdChange}
      onVisitorTypesChange={onVisitorTypesChange}
      onScreensaversChange={onScreensaversChange}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitError={submitError}
    />
  );
}

export default TerminalPage;
