import { useState } from "react";
import FlowPageView from "./FlowPageView";
import useCustomEffect from "../../../hooks/useCustomEffect";
import { useParams } from "react-router-dom";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";

function FlowPage() {
  const { flow } = useParams();
  const callEvaApi = useEvaApi();

  const [submitError, setSubmitError] = useState(null);
  const [questionnaires, setQuestionnaires] = useState([]);
  const [fields, setFields] = useState([]);
  const [houserules, setHouserules] = useState([]);
  const [messageTemplates, setMessageTemplates] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [flowData, setFlowData] = useState({
    name: "",
    steps: [],
    selectedStep: null,
  });

  /**
   * UseEffect for initializations.
   */
  useCustomEffect(() => {
    if (flow) {
      fetchFlowData();
    }

    fetchQuestionnaires();
    fetchFields();
    fetchHouserules();
    fetchMessageTemplates();
    fetchEmployees();
  });

  /**
   * This method will fetch the questionnaires.
   */
  async function fetchQuestionnaires() {
    await callEvaApi(
      new EvaApiCall("questionnaires")
        .setLoadingGroup("flow_page_fetch")
        .setRedirectOnError(true)
        .setOnSuccess((response) => {
          setQuestionnaires(response.data.data.records);
        })
    );
  }

  /**
   * This method will fetch the fields.
   */
  async function fetchFields() {
    await callEvaApi(
      new EvaApiCall("fields")
        .setLoadingGroup("flow_page_fetch")
        .setRedirectOnError(true)
        .setOnSuccess((response) => {
          setFields(response.data.data.records);
        })
    );
  }

  /**
   * This method will fetch the house rules.
   */
  async function fetchHouserules() {
    await callEvaApi(
      new EvaApiCall("files")
        .setLoadingGroup("flow_page_fetch")
        .setParams({
          where: [{ column: "type", values: ["houserule"] }],
        })
        .setRedirectOnError(true)
        .setOnSuccess((response) => {
          setHouserules(response.data.data.records);
        })
    );
  }

  /**
   * This method will fetch the message templates.
   */
  async function fetchMessageTemplates() {
    await callEvaApi(
      new EvaApiCall("message_templates")
        .setLoadingGroup("flow_page_fetch")
        .setRedirectOnError(true)
        .setOnSuccess((response) => {
          setMessageTemplates(response.data.data.records);
        })
    );
  }

  /**
   * This method will fetch the employees.
   */
  async function fetchEmployees() {
    await callEvaApi(
      new EvaApiCall("employees")
        .setLoadingGroup("flow_page_fetch")
        .setRedirectOnError(true)
        .setOnSuccess((response) => {
          setEmployees(response.data.data.records);
        })
    );
  }

  /**
   * This method will fetch the flow data.
   */
  async function fetchFlowData() {
    await callEvaApi(
      new EvaApiCall(`flows/${flow}`)
        .setLoadingGroup("flow_page_fetch")
        .setParams({
          with_flow_steps_data: true,
          with_flow_step_fields_data: true,
          with_flow_step_messages_data: true,
        })
        .setRedirectOnError(true)
        .setOnSuccess((response) => {
          setFlowData((current) => ({
            ...current,
            name: response.data.data.name,
            steps: response.data.data.steps.map((step) => ({
              type: step.type,
              score: step.score,
              file_id: step.file,
              questionnaire_id: step.questionnaire,
              on_fail: step.on_fail,
              on_fail_step: step.on_fail_step,
              fields: step.fields.map((row) =>
                row.map((field) => ({
                  field_id: field.field,
                  required_terminal: field.required_terminal,
                  required_backoffice: field.required_backoffice,
                  clear: field.clear,
                }))
              ),
              messages: step.messages.map((message) => ({
                message_template_id: message.message_template,
                receiver_type: message.receiver_type,
                employee_id: message.employee,
              })),
            })),
          }));
        })
    );
  }

  return (
    <FlowPageView
      flow={flow}
      flowData={flowData}
      setFlowData={setFlowData}
      questionnaires={questionnaires}
      fields={fields}
      houserules={houserules}
      messageTemplates={messageTemplates}
      employees={employees}
      submitError={submitError}
      setSubmitError={setSubmitError}
    />
  );
}

export default FlowPage;
