import {
  faCaretDown,
  faCaretUp,
  faCaretLeft,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";
import { useLanguageContext } from "../../../context/LanguageContext";
import {
  StyledAside,
  StyledFullNav,
  StyledFullNavButton,
  StyledFullNavGroup,
  StyledFullNavWrapper,
  StyledLogo,
  StyledLogoWrapper,
  StyledNav,
  StyledNavAction,
  StyledNavActions,
  StyledNavButton,
  StyledNavButtonLabel,
  StyledNavGroup,
  StyledNavGroupButton,
  StyledNavWrapper,
  StyledVersionWrapper,
} from "./DashboardNavStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import { StyledFlexColumn } from "../../../styles/LayoutStyles";
import { useTheme } from "styled-components";
import Version from "../../../components/version/Version";

function DashboardNavView({
  dashboardNavConfig,
  folded,
  openFullNav,
  onFoldedChange,
  onFullNavOpenButtonClick,
  onFullNavWrapperClick,
  onNavigateTo,
  getNavGroupActive,
  onNavGroupActiveChange,
}) {
  const theme = useTheme();
  const location = useLocation();
  const { translate } = useLanguageContext();

  return (
    <>
      <StyledAside $folded={folded}>
        <StyledLogoWrapper>
          {/* Dashboard EVA Logo */}
          <StyledLogo
            src={`/assets/images/${theme.logo}`}
            alt="EVA"
            $hide={folded}
          />

          {/* Dashboard Navigation Foldout Button */}
          <StyledNavActions>
            <StyledNavAction
              onClick={() => onFoldedChange(!folded)}
              title={translate(
                `eva.main.general.${folded ? "expand" : "collapse"}`
              )}
              $hideOnFoldWidth={true}
            >
              <FontAwesomeIcon icon={folded ? faCaretLeft : faCaretRight} />
            </StyledNavAction>

            <StyledNavAction
              onClick={onFullNavOpenButtonClick}
              title={translate(
                `eva.main.general.${!openFullNav ? "expand" : "collapse"}`
              )}
            >
              <FontAwesomeIcon icon={openFullNav ? faCaretDown : faCaretUp} />
            </StyledNavAction>
          </StyledNavActions>
        </StyledLogoWrapper>

        <StyledNavWrapper>
          <StyledNav>
            {dashboardNavConfig.map(
              (groupConfig) =>
                !groupConfig.getDisabled() && (
                  <StyledFlexColumn key={groupConfig.uniqueKey}>
                    <StyledNavGroupButton
                      $folded={folded}
                      onClick={() => onNavGroupActiveChange(groupConfig)}
                    >
                      <StyledNavGroup>{groupConfig.label}</StyledNavGroup>
                      <FontAwesomeIcon
                        icon={
                          getNavGroupActive(groupConfig)
                            ? faCaretDown
                            : faCaretUp
                        }
                      />
                    </StyledNavGroupButton>

                    {getNavGroupActive(groupConfig) &&
                      groupConfig.buttonConfigs.map(
                        (buttonConfig, index) =>
                          !buttonConfig.getDisabled() && (
                            <StyledNavButton
                              onClick={() =>
                                onNavigateTo(buttonConfig.routeConfig.path)
                              }
                              $active={buttonConfig.getActive(location)}
                              key={buttonConfig.routeConfig.path + index}
                              title={buttonConfig.routeConfig.label}
                            >
                              <FontAwesomeIcon icon={buttonConfig.icon} />
                              <StyledNavButtonLabel $folded={folded}>
                                {buttonConfig.routeConfig.label}
                              </StyledNavButtonLabel>
                            </StyledNavButton>
                          )
                      )}
                  </StyledFlexColumn>
                )
            )}
          </StyledNav>
        </StyledNavWrapper>

        <StyledVersionWrapper>
          <Version />
        </StyledVersionWrapper>
      </StyledAside>

      {openFullNav && (
        <StyledFullNavWrapper $folded={folded} onClick={onFullNavWrapperClick}>
          <StyledFullNav>
            {dashboardNavConfig.map(
              (groupConfig) =>
                !groupConfig.getHidden() && (
                  <div>
                    <StyledFullNavGroup>{groupConfig.label}</StyledFullNavGroup>

                    {groupConfig.buttonConfigs.map(
                      (buttonConfig, index) =>
                        !buttonConfig.hidden && (
                          <StyledFullNavButton
                            onClick={() =>
                              onNavigateTo(buttonConfig.routeConfig.path)
                            }
                            $active={buttonConfig.getActive(location)}
                            disabled={buttonConfig.getDisabled()}
                            key={buttonConfig.routeConfig.path + index}
                          >
                            {buttonConfig.routeConfig.label}
                          </StyledFullNavButton>
                        )
                    )}
                  </div>
                )
            )}
          </StyledFullNav>
        </StyledFullNavWrapper>
      )}
    </>
  );
}

export default DashboardNavView;
