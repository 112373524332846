import { useState } from "react";
import TerminalsPageView from "./TerminalsPageView";
import useCustomEffect from "../../../hooks/useCustomEffect";
import useEvaApi, { EvaApiCall } from "../../../hooks/useEvaApi";

function TerminalsPage() {
  const callEvaApi = useEvaApi();
  const [showPasswordEdit, setShowPasswordEdit] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [terminalUserData, setTerminalUserData] = useState({
    id: null,
    email: "",
    password: "",
    name: "",
    language_id: null,
  });

  /**
   * initialize the page
   */
  useCustomEffect(() => {
    fetchTerminalUserData();
  });

  /**
   * This method will fetch the terminal user from the API
   */
  async function fetchTerminalUserData() {
    await callEvaApi(
      new EvaApiCall("users")
        .setLoadingGroup("terminals_page_user_fetch")
        .setParams({
          ignore_terminals: false,
          where: [{ column: "role_type", values: ["terminal"] }],
        })
        .setOnSuccess((response) => {
          if (response.data?.data?.records?.length > 0) {
            const terminalUser = response.data.data.records[0];

            setTerminalUserData((current) => ({
              ...current,
              email: terminalUser.email,
              id: terminalUser.id,
              name: terminalUser.name,
              language_id: terminalUser.language,
            }));
          }
        })
    );
  }

  /**
   * Update the password
   * @param {event} e
   */
  function onPasswordChange(e) {
    setTerminalUserData((current) => ({
      ...current,
      password: e.target.value,
    }));
  }

  /**
   * Handle the show password edit change
   * @param {string} value
   */
  function onShowPasswordEditChange(value) {
    setShowPasswordEdit(value);
    setTerminalUserData((current) => ({
      ...current,
      password: "",
    }));
  }

  /**
   * This method will send a request to the API for updating the terminal password
   */
  async function handleSubmit() {
    await callEvaApi(
      new EvaApiCall(`users/${terminalUserData.id}`)
        .setLoadingGroup("terminals_page_user_submit")
        .setMethod("PUT")
        .setData(terminalUserData, ["id"])
        .setErrorState(setSubmitError)
        .setOnSuccess(() => {
          setShowPasswordEdit(false);
          setTerminalUserData((current) => ({
            ...current,
            password: "",
          }));
        })
    );
  }

  return (
    <TerminalsPageView
      terminalUserData={terminalUserData}
      showPasswordEdit={showPasswordEdit}
      onShowPasswordEditChange={onShowPasswordEditChange}
      submitError={submitError}
      handleSubmit={handleSubmit}
      onPasswordChange={onPasswordChange}
    />
  );
}

export default TerminalsPage;
