import { useRef, useState } from "react";
import VisitorTypePageView from "./VisitorTypePageView";
import useEvaApi, { EvaApiCall } from "../../../../hooks/useEvaApi";
import useCustomEffect from "../../../../hooks/useCustomEffect";
import { useFrontofficeContext } from "../../../../context/FrontofficeContext";

function VisitorTypePage() {
  const callEvaApi = useEvaApi();
  const { pageParams, updatePage, terminal } = useFrontofficeContext();
  const visitorType = terminal?.visitor_types?.find(
    (vt) => parseInt(vt.id) === parseInt(pageParams.visitorTypeId)
  );
  const simulated =
    pageParams.simulated !== undefined ? pageParams.simulated : false;
  const simulateNextStep = useRef(false);
  const signIn = pageParams.signIn !== undefined ? pageParams.signIn : true;
  const layout = visitorType?.layout || terminal?.layout;
  const [errors, setErrors] = useState({});
  const [step, setStep] = useState(null);
  const [disabledNavItems, setDisabledNavItems] = useState([]);
  const [visitorData, setVisitorData] = useState({
    sign_in_terminal_id: terminal.id,
    visitor_type_id: visitorType.id,
    ...getInitialVisitorData(),
  });

  /**
   * UseEffect for processing the current step or fetching the first step if it's not set
   */
  useCustomEffect(() => {
    onNextStep();
  });

  /**
   * UseEffect for simulating the next step
   */
  useCustomEffect(() => {
    if (simulateNextStep.current) {
      onNextStep();
      simulateNextStep.current = false;
    }
  }, [step]);

  /**
   * This method will return the initial visitor data
   * @returns {object}
   */
  function getInitialVisitorData() {
    const visitor = pageParams.visitor;
    const initData = {};
    if (!visitor) {
      return initData;
    }

    // Set the visitor employee
    if (visitor.employee) {
      initData.employee_id = visitor.employee;
    }

    // Set the visitor fields
    visitor.fields.forEach((visField) => {
      initData[`field_${visField.field}`] =
        visField.value ?? visField.field_option;
    });

    //Set the visitor questions
    visitor.questions.forEach((visQuestion) => {
      initData[`question_${visQuestion.question}`] =
        visQuestion.question_answer;
    });

    // Set the visitor houserules
    visitor.houserules.forEach((visHouserule) => {
      initData[`houserule_${visHouserule.file}`] = true;
    });

    return initData;
  }

  /**
   * This method will try to get the next step
   */
  async function onNextStep() {
    const flowId = signIn
      ? visitorType.sign_in_flow
      : visitorType.sign_out_flow;

    if (!flowId) {
      onFlowComplete(true);
      return;
    }

    await callEvaApi(
      new EvaApiCall(`flows/${flowId}/steps/next${step ? `/${step?.id}` : ""}`)
        .setLoadingGroup("visitor_type_next_step")
        .setMethod("POST")
        .setParams(getFetchParams())
        .setErrorState(setErrors)
        .setData(visitorData)
        .setAlertError(true)
        .setAlertSuccess(false)
        .setOnSuccess((response) => {
          const newStep = response.data.data;
          if (newStep) {
            setStep(newStep);
            setDisabledNavItems([]);

            // If the step is simulated, set the simulateNextStep flag
            if (simulated) {
              simulateNextStep.current = true;
            }
          } else {
            onFlowComplete();
          }
        })
    );
  }

  /**
   * This method will try to go back to the previous step
   * @param {int|null} stepId
   */
  async function onPreviousStep() {
    await callEvaApi(
      new EvaApiCall(
        `flows/${
          signIn ? visitorType.sign_in_flow : visitorType.sign_out_flow
        }/steps/previous/${step?.id}`
      )
        .setLoadingGroup("visitor_type_previous_step")
        .setMethod("POST")
        .setParams(getFetchParams())
        .setAlertSuccess(false)
        .setOnSuccess((response) => {
          const newStep = response.data.data;
          setStep(newStep);
          setDisabledNavItems([]);
        })
    );
  }

  /**
   * This method will handle the flow completion event
   */
  async function onFlowComplete(simplified = false) {
    const visitorId = pageParams.visitor?.id;
    await callEvaApi(
      new EvaApiCall(
        `visitors/${signIn ? "sign_in" : "sign_out"}${
          visitorId ? `/${visitorId}` : ""
        }${simplified ? "/simplified" : ""}`
      )
        .setLoadingGroup("visitor_type_complete")
        .setMethod("POST")
        .setData(visitorData)
        .setAlertSuccess(true)
        .setOnSuccess(() => {
          updatePage("start");
        })
    );
  }

  /**
   * This method will return the fetch params for the API
   * @returns {object}
   */
  function getFetchParams() {
    return {
      with_flow_step_fields_data: true,
      with_flow_step_field_field_data: true,
      with_flow_step_questionnaire_data: true,
      with_flow_step_file_data: true,
      with_field_options_data: true,
      with_questionnaire_questions_data: true,
      with_question_answers_data: true,
      with_question_image_data: true,
    };
  }

  return (
    <VisitorTypePageView
      layout={layout}
      step={step}
      errors={errors}
      visitorData={visitorData}
      disabledNavItems={disabledNavItems}
      setDisabledNavItems={setDisabledNavItems}
      setVisitorData={setVisitorData}
      onNextStep={onNextStep}
      onPreviousStep={onPreviousStep}
    />
  );
}

export default VisitorTypePage;
