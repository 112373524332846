class EVAApiHelper {
  /**
   * This method will add the given value to the given formdata object
   * @param {FormData} formData
   * @param {any} value
   * @param {string} key
   * @param {string[]} ignore
   * @returns
   */
  static addToFormData(formData, value, key = "", ignore = ["fetched"]) {
    //Check if this key should be ignored
    if (
      ignore.includes(key) ||
      ignore.includes(key.replace(/\[(\d+)\]/g, "[*]"))
    ) {
      return;
    }

    //array values
    if (Array.isArray(value)) {
      value.forEach((arrayValue, index) => {
        this.addToFormData(formData, arrayValue, `${key}[${index}]`, ignore);
      });
    }
    //File values
    else if (
      value instanceof File ||
      value instanceof Blob ||
      value instanceof FileList
    ) {
      formData.append(key, value);
    }
    //object values
    else if (typeof value === "object") {
      for (const objectKey in value) {
        this.addToFormData(
          formData,
          value[objectKey],
          key.length > 0 ? `${key}[${objectKey}]` : objectKey,
          ignore
        );
      }
    }
    //boolean values
    else if (typeof value === "boolean") {
      formData.append(key, value ? 1 : 0);
    }
    //Normal values
    else if (value !== null && value !== undefined) {
      formData.append(key, value);
    }
  }

  /**
   * This method will convert the given data in a usable formdata object
   * @param {object} data
   * @param {bool} put
   * @param {string[]} $ignore
   * @returns {FormData}
   */
  static createFormData(data, put = false, ignore = ["fetched"]) {
    const formData = new FormData();
    this.addToFormData(formData, data, "", ignore);

    if (put) {
      formData.append("_method", "PUT");
    }

    return formData;
  }

  /**
   * This method will find and return the error which belongs to the given key in the given response
   * @param {string} key
   * @param {object} errorResponse
   * @param {bool} force - If true, it will return the error even if the key is message
   * @returns {string|null}
   */
  static getResponseError(key, errorResponse, force = false) {
    //Get the errors from the response
    const errors = this.getResponseErrors(errorResponse);

    //If the key is message, just return the message from the response
    if (key === "message") {
      return Object.keys(errors).length <= 0 || force
        ? errorResponse?.response?.data?.message
        : "";
    }

    //Find the error value
    for (const errorKey in errors) {
      if (errorKey === key) {
        return errors[errorKey][0];
      }
    }
  }

  /**
   * This method will find and return the errors which have a key that is like the given key in the given response
   * @param {string} like
   * @param {object} errorResponse
   * @param {function(string, object[]):string} translate
   * @returns {string|null}
   */
  static getResponseErrorsLike(like, errorResponse, translate) {
    //Get the errors from the response
    const errors = this.getResponseErrors(errorResponse);

    //Find all errors that are like the given key
    let errorArray = [];
    for (const errorKey in errors) {
      if (errorKey.includes(like)) {
        errorArray.push(errors[errorKey][0]);
      }
    }

    if (errorArray.length > 0) {
      return (
        errorArray[0] +
        (errorArray.length > 1
          ? ` ${translate("eva.main.validation.more_errors", [
              { key: "count", value: errorArray.length - 1 },
            ])}`
          : "")
      );
    } else {
      return null;
    }
  }

  /**
   * This method will find and return the error array which belongs to the given key in the given response
   * @param {string} key
   * @param {object} errorResponse
   * @param {function(index):string} arrayIndexIdentifier
   * @returns {string|null}
   */
  static getResponseErrorArray(key, errorResponse, arrayIndexIdentifier) {
    //Get the errors from the response
    const errors = this.getResponseErrors(errorResponse);
    const keyParts = key.split(".");

    //Determine the errorTypes
    let errorTypes = {};
    for (const errorKey in errors) {
      if (
        errorKey.startsWith(keyParts[0]) &&
        errorKey.includes(".") &&
        (errorKey.endsWith(keyParts[keyParts.length - 1]) ||
          keyParts.length === 1)
      ) {
        const errorKeySplit = errorKey.split(".");
        const errorKeyArrayIndex = errorKeySplit[1];

        //If the error key is an actual part of an array
        if (!isNaN(errorKeyArrayIndex)) {
          //Create the errorType with ___INDEX___ as the index so we can replace that at the end with the value we want
          const errorType = errors[errorKey][0].replace(
            errorKeyArrayIndex,
            "___INDEX___"
          );

          //Determine the errorType index for this error key
          let errorTypeIndex = arrayIndexIdentifier
            ? arrayIndexIdentifier(errorKeyArrayIndex)
            : errorKeyArrayIndex;
          errorTypeIndex = `<strong><em>${errorTypeIndex}</em></strong>`;

          //Add the errorTypeIndex to the errorType array or create the array if it does not exist yet
          if (errorTypes[errorType]) {
            if (!errorTypes[errorType].includes(errorTypeIndex)) {
              errorTypes[errorType].push(errorTypeIndex);
            }
          } else {
            errorTypes[errorType] = [errorTypeIndex];
          }
        }
      }
    }

    //Create the actual error array
    let errorArray = null;
    for (let errorType in errorTypes) {
      //Convert the error type array to a single string
      const errorTypeIndexes = errorTypes[errorType].join(" | ");

      //Replace the ___INDEX___ with the errorTypeIndexes value
      errorType = errorType.replace("___INDEX___", errorTypeIndexes);

      //Add the errorType to the errorArray
      if (errorArray) {
        errorArray += `<br />${errorType}`;
      } else {
        errorArray = errorType;
      }
    }

    return errorArray;
  }

  /**
   * This method will get the errors from the given response
   * @param {object} errorResponse
   * @returns {object|null}
   */
  static getResponseErrors(errorResponse) {
    if (errorResponse?.response?.data?.errors) {
      return { ...errorResponse?.response?.data?.errors };
    } else {
      return {};
    }
  }

  /**
   * This method will create an error response object
   * @param {string} error
   * @returns {object}
   */
  static createResponseError(error) {
    return {
      response: {
        data: {
          message: error,
        },
      },
    };
  }
}

export default EVAApiHelper;
