import styled from "styled-components";

export const StyledVersion = styled.label`
  color: ${(props) => props.theme.colors.primary.lightest};
  display: inline-block;
  position: ${(props) => (props.fixed ? "fixed" : "initial")};
  bottom: ${(props) => (props.fixed ? props.theme.spacing.large : "initial")};
  left: ${(props) => (props.fixed ? props.theme.spacing.large : "initial")};
  background-color: ${(props) =>
    props.fixed ? props.theme.colors.primary.darkest : "transparent"};
  padding: ${(props) => (props.fixed ? props.theme.spacing.small : "0px")};
  border-radius: ${(props) =>
    props.fixed ? props.theme.spacing.normal : "0px"};
  font-weight: bold;
`;
