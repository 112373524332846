import FlowFields from "../../../../../../components/flowFields/FlowFields";

function FieldsStepView({ step, errors, visitorData, setVisitorData }) {
  return (
    <FlowFields
      step={step}
      data={visitorData}
      setData={setVisitorData}
      errors={errors}
    />
  );
}

export default FieldsStepView;
