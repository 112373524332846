import { useState } from "react";
import useCustomEffect from "../../../../../../hooks/useCustomEffect";
import useEvaApi, { EvaApiCall } from "../../../../../../hooks/useEvaApi";
import EmployeeStepView from "./EmployeeStepView";

function EmployeeStep({ errors, visitorData, setVisitorData }) {
  const callEvaApi = useEvaApi();
  const [employees, setEmployees] = useState([]);

  /**
   * UseEffect for automatically fetching the employees if it's not set
   */
  useCustomEffect(() => {
    fetchEmployees();
  });

  /**
   * This method will fetch the employees from the API
   */
  async function fetchEmployees() {
    await callEvaApi(
      new EvaApiCall("employees")
        .setLoadingGroup("employee_step_fetch")
        .setOnSuccess((response) => {
          setEmployees(response.data.data.records);
        })
    );
  }

  /**
   * This method will handle the employee id change event
   * @param {any} value
   */
  function onEmployeeIdChange(value) {
    setVisitorData((prev) => ({ ...prev, employee_id: value }));
  }

  return (
    <EmployeeStepView
      errors={errors}
      visitorData={visitorData}
      employees={employees}
      onEmployeeIdChange={onEmployeeIdChange}
    />
  );
}

export default EmployeeStep;
