import { useTheme } from "styled-components";
import { useLanguageContext } from "../../context/LanguageContext";
import { StyledFlexColumn } from "../../styles/LayoutStyles";
import Modal from "../modal/Modal";
import {
  StyledQuestionnaire,
  StyledQuestionnaireWrapper,
  StyledQuestionWrapper,
} from "./VisitorQuestionsDisplayStyles";

function VisitorQuestionsDisplayView({
  visitor,
  loadingGroup,
  questionnaires,
  onClose,
}) {
  const theme = useTheme();
  const { translateEntity, translate } = useLanguageContext();

  return (
    visitor && (
      <Modal
        title={translate("eva.main.entities.questions")}
        onClose={onClose}
        loadingGroups={loadingGroup}
      >
        <StyledFlexColumn style={{ gap: theme.spacing.large }}>
          {questionnaires.map((questionnaire) => {
            const questions = questionnaire.questions.filter((question) =>
              Object.keys(visitor.questions).includes(question.name)
            );

            return (
              questions.length > 0 && (
                <StyledQuestionnaireWrapper key={questionnaire.id}>
                  <StyledQuestionnaire>
                    {questionnaire.name}
                  </StyledQuestionnaire>

                  <StyledFlexColumn>
                    {questions.map((question) => {
                      const answer = question.answers.find(
                        (answer) =>
                          answer.name === visitor.questions[question.name]
                      );
                      if (!answer) return "";

                      return (
                        <StyledQuestionWrapper key={question.id}>
                          <p>
                            <b>{translateEntity(question, "question")}</b>
                          </p>
                          <p>{translateEntity(answer, "answer")}</p>
                        </StyledQuestionWrapper>
                      );
                    })}
                  </StyledFlexColumn>
                </StyledQuestionnaireWrapper>
              )
            );
          })}
        </StyledFlexColumn>
      </Modal>
    )
  );
}

export default VisitorQuestionsDisplayView;
