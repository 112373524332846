import { useState } from "react";
import useCustomEffect from "../../hooks/useCustomEffect";
import useEvaApi, { EvaApiCall } from "../../hooks/useEvaApi";
import VisitorQuestionsDisplayView from "./visitorQuestionsDisplayView";

function VisitorQuestionsDisplay({
  visitor,
  loadingGroup = "visitor_questions_fetch",
  onClose,
}) {
  const callEvaApi = useEvaApi();
  const [questionnaires, setQuestionnaires] = useState([]);
  const [fetched, setFetched] = useState(false);

  /**
   * UseEffect for fetching the questionnaires
   */
  useCustomEffect(() => {
    fetchQuestionnaires();
  }, [visitor]);

  /**
   * Fetches the questionnaires that are not already fetched
   */
  async function fetchQuestionnaires() {
    if (!visitor || fetched) return;

    await callEvaApi(
      new EvaApiCall("questionnaires")
        .setLoadingGroup(loadingGroup)
        .setParams({
          with_questionnaire_questions_data: true,
          with_question_answers_data: true,
        })
        .setAlertError(true)
        .setAlertSuccess(false)
        .setOnSuccess((response) => {
          setQuestionnaires((prev) => response.data.data.records);
          setFetched(true);
        })
    );
  }

  return (
    <VisitorQuestionsDisplayView
      visitor={visitor}
      loadingGroup={loadingGroup}
      onClose={onClose}
      questionnaires={questionnaires}
    />
  );
}

export default VisitorQuestionsDisplay;
